import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";
import Gallery from "../components/Gallery";

export const DisplaySizesTemplate = ({
  title,
  helmet,
  displaySizeGallery,
  galleries
}) => {
  return (
    <Fragment>
      <PageHeader title={"Display Sizes"} paintColor={"blue"} />
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-10 is-offset-1">
            <section className="section is-small">
              <div className="has-text-centered">
                {/* <h3 className="is-size-2 title-underline">

                </h3> */}
                <p className="is-size-5">
                  Fitting your booth rentals to the size of your designated
                  exhibitor space is crucial to maximizing your exhibition
                  potential. Unveil Production Group has an extensive range of
                  available display sizes and layouts to choose from. Our
                  representatives will work with you to find the perfect display
                  size to fit your space and if it does not already exist, we
                  will build it custom.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="10x10" className="is-size-2 title-underline">
            10 x 10
          </h3>
        </div>
        <Gallery slides={galleries._10x10.edges} type={"displaySizes"} />
      </section>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="10x20" className="is-size-2 title-underline">
            10 x 20
          </h3>
        </div>
        <Gallery slides={galleries._10x20.edges} type={"displaySizes"} />
      </section>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="10x30" className="is-size-2 title-underline">
            10 x 30
          </h3>
        </div>
        <Gallery slides={galleries._10x30.edges} type={"displaySizes"} />
      </section>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="20x20" className="is-size-2 title-underline">
            20 x 20
          </h3>
        </div>
        <Gallery slides={galleries._20x20.edges} type={"displaySizes"} />
      </section>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="20x30" className="is-size-2 title-underline">
            20 x 30
          </h3>
        </div>
        <Gallery slides={galleries._20x30.edges} type={"displaySizes"} />
      </section>
      <section className="section is-medium">
        <div className="has-text-centered">
          <h3 id="20x40" className="is-size-2 title-underline">
            20 x 40
          </h3>
        </div>
        {galleries._20x40.edges.length == 1 ? (
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div className="has-text-centered">
                  <img
                    src={
                      galleries._20x40.edges[0].node.childImageSharp.fluid.src
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Gallery slides={galleries._20x40.edges} type={"displaySizes"} />
        )}
      </section>
    </Fragment>
  );
};

DisplaySizesTemplate.propTypes = {
  // content: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const DisplaySizePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      {console.log(data)}
      <DisplaySizesTemplate
        galleries={data}
        // content={post.html}
        // description={post.frontmatter.description}
        // subServices={
        //   post.frontmatter.serviceOverview
        //     ? post.frontmatter.serviceOverview.subService
        //     : ""
        // }
        // helmet={
        //   <Helmet titleTemplate="%s | Blog">
        //     <title>{`${post.frontmatter.title}`}</title>
        //     <meta
        //       name="description"
        //       content={`${post.frontmatter.description}`}
        //     />
        //   </Helmet>
        // }
        // title={post.frontmatter.title}
        // displaySizeGallery={post.frontmatter.displaySizeGallery}
      />
    </Layout>
  );
};

DisplaySizePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default DisplaySizePage;

// export const pageQuery = graphql`
//   query DisplaySizePageByID($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       id
//       html
//       frontmatter {
//         title
//         description
//         displaySizeGallery {
//           heading
//           images {
//             image
//           }
//         }
//       }
//     }
//   }
// `;

export const pageQuery = graphql`
  query DisplaySizePageGalleries {
    _10x10: allFile(filter: { dir: { regex: "/10x10/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
    _10x20: allFile(filter: { dir: { regex: "/10x20/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
    _10x30: allFile(filter: { dir: { regex: "/10x30/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
    _20x20: allFile(filter: { dir: { regex: "/20x20/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
    _20x30: allFile(filter: { dir: { regex: "/20x30/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
    _20x40: allFile(filter: { dir: { regex: "/20x40/" } }) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          dir
          childImageSharp {
            id
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
